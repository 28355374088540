import axios from "axios";
import { urlBase, Sora } from "./ServicesConfig";

export const getToken = async (body) => {
    const respose = await axios.post(Sora('User/Login'),
        body,
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(data => {
            return data;
        })
        .catch((error) => {

            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }


        });
    return respose;
};


export const RefreshToken = async (body) => {
    const respose = await axios.post(Sora('User/RefreshToken'),
        body,
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(data => {
            return data;
        })
        .catch((error) => {

            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }


        });
    return respose;
};


export const ChangePasswordRequest = async (userName) => {
    const respose = await axios.get(Sora(`/User/ChangePasswordRequest?userName=${userName}&app=AGORA&urlBase=${urlBase}`),
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(data => {
            return data;
        })
        .catch((error) => {

            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }


        });
    return respose;
};




export const VerifyTokenChangePassword = async (token) => {
    const respose = await axios.get(Sora(`/User/VerifyTokenChangePassword?token=${token}&MarkVerification=true`),
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(data => {
            return data;
        })
        .catch((error) => {

            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }


        });
    return respose;
};




export const ResetPasswordToken = async (token, password) => {
    const respose = await axios.get(Sora(`/User/ResetPasswordToken?token=${token}&Password=${password}`),
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(data => {
            return data;
        })
        .catch((error) => {

            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }


        });
    return respose;
};