import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { RefreshToken } from './SoraService'



const BellService = () => {

    const navigate = useNavigate();

    const axiosInstance = axios.create({
        baseURL: `${process.env.REACT_APP_URL_BELL}`, // Replace with your API base URL
    });



    axiosInstance.interceptors.request.use(
        async (config) => {

            const token = localStorage.getItem("token");
            const expiredTime = localStorage.getItem("expiredTime");
            const remember = localStorage.getItem("remember");

            if (expiredTime && new Date().getTime() > expiredTime) {


                if (remember === "1") {

                    const res = await RefreshToken({ AccessToken: localStorage.getItem("token"), RefreshToken: localStorage.getItem("refreshToken") })

                    if (res.status === 200) {
                        const data = res.data.data
                        localStorage.setItem("token", data.token);
                        localStorage.setItem("refreshToken", data.refreshToken);
                        localStorage.setItem("expiredTime", Date.parse(data.expiredTime));
                        localStorage.setItem("userName", data.userName);


                        config.headers.authorization = `Bearer ${data.token}`
                    } else {
                        localStorage.removeItem("token");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("expiredTime");
                        localStorage.removeItem("userName");
                        localStorage.removeItem("fullName");
                        localStorage.removeItem("email");
                        localStorage.removeItem("phone");
                        localStorage.removeItem("avatar");
                        localStorage.removeItem("firstLogin");
                        localStorage.removeItem("remember");
                        navigate('/', { replace: true });
                    }
                } else {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expiredTime");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("fullName");
                    localStorage.removeItem("email");
                    localStorage.removeItem("phone");
                    localStorage.removeItem("avatar");
                    localStorage.removeItem("firstLogin");
                    localStorage.removeItem("remember");
                    navigate('/', { replace: true });
                }




            }
            else if (token) {
                if (config.headers) config.headers.authorization = `Bearer ${token}`;
            }

            return config;
        },
        (error) => {
            // Handle request errors here

            return Promise.reject(error);
        }
    );



    const SendTemplateEmail = async (body) => {

        const respose = await axiosInstance.post("EMail/SendTemplate",
            body,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };



    const SendTemplateSMS = async (body) => {

        const respose = await axiosInstance.post("Messages/SendTemplate",
            body,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };




    return { SendTemplateEmail, SendTemplateSMS }
}


export default BellService;