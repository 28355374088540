import { createContext, useContext, useEffect, useState } from 'react';
import TarsService from '../services/TarsService'

const GlobalProviderContext = createContext();

export const GlobalProvider = ({ children }) => {

    const [sending, setSending] = useState(false);


    const { GetPosById, GetEnumsCustomer, GetTreatments, GetGeographic, GetAdminCache, GetCountries } = TarsService();

    const [posData, setPosData] = useState(null);
    const [treatments, setTreatments] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [notificationTypes, setNotificationTypes] = useState(null);
    const [invitationsStatus, setInvitationsStatus] = useState([]);
    const [avatarData, setAvatarData] = useState({});
    const [departments, setDepartments] = useState([]);
    const [allTreatments, setAllTreatments] = useState([]);
    const [documentTypespatient, setDocumentTypespatient] = useState([]);
    const [countries, setCountries] = useState([]);
    const [patientRelations, setPatientRelations] = useState([]);

    useEffect(() => {
        setSending(true);

        GetPosById().then(res => {
            const data = JSON.parse(res.data.data)[0]
            setPosData(data);
            // console.log(data)

            // GetAdminCache().then(res => {

            //     const treatmentsTemp = []
            //     const tempSector = data.posSector.map(s => { return s.Id });
            //     const dataAdmin = JSON.parse(res.data.data).cache.find(s => s.EnumerationId === "posTreatmentsList").EnumerationValues


            //     dataAdmin.forEach(t => {

            //         if (tempSector.includes(t.Sector)) {
            //             treatmentsTemp.push(t)
            //         }

            //     });

            //     // setTreatments(dataAdmin.map(t => { return { IdTreatment: t.value, TreatmentName: t.label } }))

            //     // setAllTreatments(treatmentsTemp)
            // })
        })

        GetEnumsCustomer().then(res => {
            setSending(false);
            const enumsCustomer = JSON.parse(res.data.data)
            // console.log(enumsCustomer)
            const invitationsStatus = enumsCustomer.cache.find(s => s.EnumerationId === "cusEnumInvitationsStatus")
            const cusEnumDocumentType = enumsCustomer.cache.find(s => s.EnumerationId === "cusEnumDocumentType")

            setDocumentTypes(cusEnumDocumentType.EnumerationValues);
            setDocumentTypespatient(enumsCustomer.cache.find(s => s.EnumerationId === "patientsEnumDocumentType").EnumerationValues)
            setPatientRelations(enumsCustomer.cache.find(s => s.EnumerationId === "patientsEnumCustomerRelationship").EnumerationValues)
            setNotificationTypes(enumsCustomer.cache.find(s => s.EnumerationId === "enumNotificationType").EnumerationValues)
            setInvitationsStatus(invitationsStatus)
        });

        GetTreatments().then(res => {
            const treatmentsTemp = JSON.parse(res.data.data)[0].PosTreatments
            setTreatments(treatmentsTemp)

        })

        GetGeographic().then(res => {

            const data = JSON.parse(res.data.data).cache.CacheValues[0].Departments

            setDepartments(data)

        })


        GetCountries().then(res => {

            const data = JSON.parse(res.data.data).cache
            setCountries(data)    
        })



    }, []);




    const value = {
        posData,
        setPosData,
        treatments,
        documentTypes,
        notificationTypes,
        invitationsStatus,
        sending,
        setSending,
        avatarData,
        setAvatarData,
        departments,
        allTreatments,
        documentTypespatient,
        countries,
        patientRelations
    }

    return (
        <GlobalProviderContext.Provider value={value}>
            {children}
        </GlobalProviderContext.Provider>
    )
};

export const useGlobalProvider = () => {
    return useContext(GlobalProviderContext)
};