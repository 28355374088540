// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        ".my-loader": {
          position: "fixed",
          zIndex: 999,
          top: 0,
          left: 0,
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "rgba(255, 255, 255, 0.75)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        },
        ".my-loader-container": {
          position: "relative",
          minWidth: "320px",
          minHeight: "200px",
          textAlign: "center",
          padding: "1rem",
        },
        ".css-1fvjfkq-MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
          backgroundColor: "rgba(0, 169, 235, 20%)"
        },


        ".main-container-ip": {

          "::after": {
            content: "''",
            display: "flex",
            width: "60px",
            height: "100%",
            right: "-0",
            position: "absolute",
            zIndex: 9999999,
            pointerEvents: "none",
            top: 0,
            background: "linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .7) 40%, #fff 70%)",

          }

        },
        "#card-ip .MuiInputBase-colorPrimary": {
          marginRight: "10px"
        },
        // ".chrome-picker ": {
        //   margin: "0 auto"
        // }


      }}
    />
  );

  return inputGlobalStyles;
}
